import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useMembershipStore = defineStore('membership', () => {
    const isPaid = ref(false);

    const setPaidStatus = (status) => {
        isPaid.value = status;
    };

    return { isPaid, setPaidStatus };
});